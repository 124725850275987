import React, {
  useState,
  useEffect,
  useRef,
  Suspense,
  lazy, 
  memo,
} from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate, 
  useLocation
} from 'react-router-dom';
import usePageTracking from './hooks/usePageTracking';

import {
  MapPin,
  Phone,
  Mail,
  Star,
  Shield,
  Menu,
  X,
  ArrowRight,
  Sparkles,
  Clock,
  Heart,
  Facebook,
  Linkedin,
  ChevronDown,
  ChevronLeft,
  ChevronRight
} from 'lucide-react';
import { supabase } from './lib/supabase';
import type { Personnel, Review } from './types/database';

// Lazy load route components
const Team = lazy(() => import('./pages/Team'));
const AutoReform = lazy(() => import('./pages/AutoReform'));
const ClientPortal = lazy(() => import('./pages/ClientPortal'));
const Products = lazy(() => import('./pages/Products'));
const Contact = lazy(() => import('./pages/Contact'));
const ReferAndWin = lazy(() => import('./pages/ReferAndWin'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const Blog = lazy(() => import('./pages/Blog'));
const BlogPost = lazy(() => import('./pages/BlogPost'));

// Custom fallback loading component with spinner
function LoadingFallback() {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-[#183043] to-[#0f1f2d]">
      <div className="text-center">
        <div className="w-16 h-16 border-4 border-[#81ae40] border-t-transparent rounded-full animate-spin mx-auto mb-4" />
        <p className="text-white/90 text-lg">Loading...</p>
      </div>
    </div>
  );
}

// Scroll to top on route change
function ScrollToTop() {
  const location = useLocation();
  usePageTracking();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}

// Memoized TopBar to avoid unnecessary re-renders
const TopBar = memo(function TopBar() {
  return (
    <div className="bg-[#81ae40] text-white py-2 hidden md:block">
      <div className="container mx-auto px-8">
        <div className="flex justify-between items-center max-w-[1400px] mx-auto">
          <div className="flex items-center space-x-12">
            <a
              href="https://www.google.com/maps/place/Janus+Insurance+Agency,+Inc/@42.6873479,-84.3037613,1219m/data=!3m2!1e3!4b1!4m6!3m5!1s0x8822df9b05d77bff:0x6618949601024575!8m2!3d42.6873441!4d-84.2988904!16s%2Fg%2F1hc268166?entry=ttu"
              className="flex items-center hover:text-white/90 transition-colors"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Visit our office at 1041 W. Grand River Ave, Williamston, MI 48895"
            >
              <MapPin className="w-4 h-4 mr-2" aria-hidden="true" />
              1041 W. Grand River Ave, Williamston, MI 48895
            </a>
            <a
              href="tel:5176551665"
              className="flex items-center hover:text-white/90 transition-colors"
              aria-label="Call us at (517) 655-1665"
            >
              <Phone className="w-4 h-4 mr-2" aria-hidden="true" />
              (517) 655-1665
            </a>
            <a
              href="mailto:info@janusagents.com"
              className="flex items-center hover:text-white/90 transition-colors"
              aria-label="Email us at info@janusagents.com"
            >
              <Mail className="w-4 h-4 mr-2" aria-hidden="true" />
              info@janusagents.com
            </a>
          </div>
          <div className="flex items-center space-x-4">
            <a
              href="https://www.facebook.com/JANUSINC"
              className="hover:text-white/90 transition-colors"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Visit our Facebook page"
            >
              <Facebook className="w-5 h-5" aria-hidden="true" />
            </a>
            <a
              href="https://www.linkedin.com/company/janus-insurance-agency-inc/about/"
              className="hover:text-white/90 transition-colors"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Visit our LinkedIn page"
            >
              <Linkedin className="w-5 h-5" aria-hidden="true" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
});

// Memoized Navbar with debounced scroll listener for performance
const Navbar = memo(function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();
  const scrollTimeout = useRef<number | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollTimeout.current !== null) {
        window.clearTimeout(scrollTimeout.current);
      }
      scrollTimeout.current = window.setTimeout(() => {
        setIsScrolled(window.scrollY > 0);
      }, 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      if (scrollTimeout.current !== null) {
        window.clearTimeout(scrollTimeout.current);
      }
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleNavigation = (path: string) => {
    navigate(path);
    setIsOpen(false);
  };

  return (
    <header
      className={`fixed w-full z-50 transition-all duration-300 ${
        isScrolled ? 'bg-white/95 backdrop-blur-sm shadow-lg' : 'bg-transparent'
      }`}
    >
      <TopBar />
      <div className="container mx-auto px-8">
        <div className="flex items-center justify-between py-4 max-w-[1400px] mx-auto">
          <Link
            to="/"
            onClick={() => setIsOpen(false)}
            className={`logo-text transition-colors ${
              isScrolled ? 'text-[#183043]' : 'text-white'
            }`}
          >
            <span>J</span>ANUS
            <br />
            <span>A</span>GENTS
          </Link>

          <div className="hidden md:flex items-center space-x-12">
            <Link
              to="/team"
              className={`hover:text-[#81ae40] transition-colors ${
                isScrolled ? 'text-[#183043]' : 'text-white'
              }`}
            >
              Our Team
            </Link>
            <Link
              to="/contact"
              className={`hover:text-[#81ae40] transition-colors ${
                isScrolled ? 'text-[#183043]' : 'text-white'
              }`}
            >
              Contact Us
            </Link>
            <Link
              to="/products"
              className={`hover:text-[#81ae40] transition-colors ${
                isScrolled ? 'text-[#183043]' : 'text-white'
              }`}
            >
              Products
            </Link>
            <Link
              to="/auto-reform"
              className={`hover:text-[#81ae40] transition-colors ${
                isScrolled ? 'text-[#183043]' : 'text-white'
              }`}
            >
              Auto Reform
            </Link>
            <Link
              to="/refer-and-win"
              className={`refer-win-link hover:text-[#81ae40] transition-colors ${
                isScrolled ? 'text-[#183043]' : 'text-white'
              }`}
            >
              Refer & Win $250
            </Link>
            <Link
              to="/client-portal"
              className="bg-[#81ae40] px-6 py-2 rounded-md hover:bg-[#6c9235] transition-all transform hover:scale-105 text-white"
            >
              Client Portal
            </Link>
          </div>

          <div className="md:hidden flex items-center space-x-4">
            <Link to="/refer-and-win" className="win-mobile-button">
              Win $250
            </Link>
            <a
              href="tel:5176551665"
              className={`${
                isScrolled ? 'text-[#183043]' : 'text-white'
              } hover:text-[#81ae40] transition-colors`}
            >
              <Phone className="w-5 h-5" />
            </a>
            <button
              className={`${isScrolled ? 'text-[#183043]' : 'text-white'}`}
              onClick={() => setIsOpen(!isOpen)}
            >
              {isOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>
      </div>

      <div
        className={`md:hidden absolute w-full bg-white transition-all duration-300 ${
          isOpen ? 'opacity-100 visible' : 'opacity-0 invisible'
        }`}
      >
        <div className="container mx-auto px-8 py-4">
          <div className="flex flex-col space-y-4">
            <button
              onClick={() => handleNavigation('/team')}
              className="text-left text-[#183043] hover:text-[#81ae40] transition-colors"
            >
              Our Team
            </button>
            <button
              onClick={() => handleNavigation('/contact')}
              className="text-left text-[#183043] hover:text-[#81ae40] transition-colors"
            >
              Contact Us
            </button>
            <button
              onClick={() => handleNavigation('/products')}
              className="text-left text-[#183043] hover:text-[#81ae40] transition-colors"
            >
              Products
            </button>
            <button
              onClick={() => handleNavigation('/auto-reform')}
              className="text-left text-[#183043] hover:text-[#81ae40] transition-colors"
            >
              Auto Reform
            </button>
            <button
              onClick={() => handleNavigation('/refer-and-win')}
              className="text-left text-[#183043] hover:text-[#81ae40] transition-colors"
            >
              Refer & Win
            </button>
            <button
              onClick={() => handleNavigation('/client-portal')}
              className="bg-[#81ae40] px-6 py-2 rounded-md hover:bg-[#6c9235] transition-colors text-white"
            >
              Client Portal
            </button>
            <div className="pt-4 border-t border-gray-200">
              <a
                href="https://www.google.com/maps/place/Janus+Insurance+Agency,+Inc/@42.6873479,-84.3037613,1219m/data=!3m2!1e3!4b1!4m6!3m5!1s0x8822df9b05d77bff:0x6618949601024575!8m2!3d42.6873441!4d-84.2988904!16s%2Fg%2F1hc268166?entry=ttu"
                className="flex items-center text-[#183043] hover:text-[#81ae40] transition-colors py-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MapPin className="w-4 h-4 mr-2" />
                1041 W. Grand River Ave, Williamston, MI
              </a>
              <a
                href="mailto:info@janusagents.com"
                className="flex items-center text-[#183043] hover:text-[#81ae40] transition-colors py-2"
              >
                <Mail className="w-4 h-4 mr-2" />
                info@janusagents.com
              </a>
              <div className="flex space-x-4 py-2">
                <a
                  href="https://www.facebook.com/JANUSINC"
                  className="text-[#183043] hover:text-[#81ae40] transition-colors"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Facebook className="w-5 h-5" />
                </a>
                <a
                  href="https://www.linkedin.com/company/janus-insurance-agency-inc/about/"
                  className="text-[#183043] hover:text-[#81ae40] transition-colors"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Linkedin className="w-5 h-5" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
});

function HomePage() {
  const [featuredAgents, setFeaturedAgents] = useState<Personnel[]>([]);
  const [reviews, setReviews] = useState<Review[]>([]);
  const [activeTestimonial, setActiveTestimonial] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [heroImages, setHeroImages] = useState<string[]>([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [errors, setErrors] = useState<{
    personnel?: string;
    reviews?: string;
    hero?: string;
    general?: string;
  }>({});
  const navigate = useNavigate();
  const featuresRef = useRef<HTMLDivElement>(null);

  // Fetch personnel and reviews together with error handling
  useEffect(() => {
    async function fetchData() {
      try {
        const { data: personnelData, error: personnelError } = await supabase
          .from('personnel')
          .select('*')
          .order('display_order');

        if (personnelError) {
          setErrors(prev => ({ ...prev, personnel: personnelError.message }));
          return;
        }

        const owners = personnelData?.filter(p => p.role_type === 'owner') || [];
        const others = personnelData?.filter(p => p.role_type !== 'owner') || [];
        const randomOther = others[Math.floor(Math.random() * others.length)];
        const featured = [owners[0], randomOther, owners[1]].filter(Boolean);
        setFeaturedAgents(featured);

        const { data: reviewsData, error: reviewsError } = await supabase
          .from('reviews')
          .select('*');
        if (reviewsError) {
          setErrors(prev => ({ ...prev, reviews: reviewsError.message }));
          return;
        }
        setReviews(reviewsData || []);
      } catch (error) {
        console.error('Error fetching personnel or reviews:', error);
        setErrors(prev => ({ ...prev, general: 'Failed to load content' }));
      }
    }
    fetchData();
  }, []);

  // Fetch hero images with preloading and error handling
  useEffect(() => {
    async function fetchHeroImages() {
      try {
        const { data, error } = await supabase
          .from('hero_images')
          .select('*')
          .order('display_order');
        if (error) {
          setErrors(prev => ({ ...prev, hero: error.message }));
          return;
        }
        const imageUrls = data.map(img => img.image_url);
        setHeroImages(imageUrls);
        imageUrls.forEach(url => {
          const img = new Image();
          img.src = url;
        });
      } catch (error) {
        console.error('Failed to load hero images:', error);
        setErrors(prev => ({ ...prev, hero: 'Failed to load hero images' }));
      }
    }
    fetchHeroImages();
  }, []);

  useEffect(() => {
    if (heroImages.length > 0) {
      const interval = setInterval(() => {
        setCurrentImageIndex(prev => (prev + 1) % heroImages.length);
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [heroImages]);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleAgentClick = (agentId: string) => {
    navigate('/team', { state: { scrollToId: agentId } });
  };

  const nextTestimonial = () => {
    setActiveTestimonial(prev => (prev + 1) % reviews.length);
  };

  const prevTestimonial = () => {
    setActiveTestimonial(prev => (prev - 1 + reviews.length) % reviews.length);
  };

  return (
    <div className="min-h-screen">
      <section className="relative min-h-[100vh] md:min-h-screen flex items-center overflow-hidden pt-16 md:pt-0">
        <div className="absolute inset-0">
          <div className="absolute inset-0 hero-gradient opacity-90 z-10" />
          {heroImages?.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Hero image ${index + 1}`}
              loading={index === 0 ? 'eager' : 'lazy'}
              fetchPriority={index === 0 ? 'high' : 'low'}
              className={`absolute inset-0 w-full h-full object-cover object-center hero-image ${
                index === currentImageIndex ? 'opacity-100 active' : 'opacity-0'
              }`}
            />
          ))}
        </div>

        <div className="relative container mx-auto px-8 py-6 sm:py-10 z-20">
          <div className="max-w-3xl md:max-w-[800px] text-center md:text-left">
            <h1
              className={`hero-title text-4xl sm:text-5xl md:text-7xl font-bold text-white mb-4 sm:mb-6 transition-all duration-1000 transform ${
                isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
              }`}
            >
              <div><span>J</span>ANUS</div>
              <div><span>I</span>NSURANCE</div>
              <div><span>A</span>GENCY</div>
            </h1>
            <p
              className={`text-lg sm:text-xl text-white/90 mb-6 sm:mb-8 transition-all duration-1000 delay-300 transform ${
                isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
              } md:max-w-[600px]`}
            >
              From Home, Auto, and Life, to Business, Commercial, and more, We've Got You
              Covered.
            </p>
            <div
              className={`flex flex-col sm:flex-row gap-3 sm:gap-4 justify-center md:justify-start transition-all duration-1000 delay-500 transform ${
                isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
              }`}
            >
              <a
                href="tel:5176551665"
                className="bg-[#81ae40] w-full sm:w-auto px-6 sm:px-8 py-3 sm:py-4 rounded-md text-white text-base sm:text-lg font-medium hover:bg-[#6c9235] transition-all transform hover:scale-105 flex items-center justify-center group"
              >
                Text an Agent
                <ArrowRight className="ml-2 transition-transform group-hover:translate-x-1" />
              </a>
              <Link
                to="/refer-and-win"
                className="border-2 border-white w-full sm:w-auto px-6 sm:px-8 py-3 sm:py-4 rounded-md text-white text-base sm:text-lg font-medium hover:bg-white/10 transition-all text-center"
                onClick={() => window.scrollTo(0, 0)}
              >
                Refer a friend—win $250
              </Link>
            </div>
          </div>
        </div>

        {Object.keys(errors).length > 0 && (
          <div className="bg-red-50 text-red-600 p-4 rounded-lg mb-8">
            <p className="font-medium">Error loading content:</p>
            <ul className="list-disc list-inside mt-2">
              {Object.entries(errors).map(([key, error]) => (
                <li key={key}>{error}</li>
              ))}
            </ul>
          </div>
        )}

        <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 animate-bounce z-20">
          <ChevronDown className="w-8 h-8 text-white" />
        </div>
      </section>

      <section className="py-24 relative overflow-hidden bg-gradient-to-b from-white via-gray-50 to-white">
        <div className="container mx-auto px-8">
          <h2 className="text-4xl font-bold text-center mb-16">
            <span className="text-gradient">Meet Our Featured Agents</span>
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {featuredAgents.map(agent => (
              <div
                key={agent.id}
                className="card-hover rounded-lg p-6 cursor-pointer"
                onClick={() => handleAgentClick(agent.id)}
              >
                <img
                  src={agent.image_url}
                  alt={agent.name}
                  className="w-24 h-24 rounded-full mx-auto mb-4 object-cover"
                />
                <h3 className="text-xl font-semibold text-center mb-2">{agent.name}</h3>
                <p className="text-[#81ae40] text-center mb-4">{agent.title}</p>
                <p className="text-gray-600 text-center text-sm">{agent.bio}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="py-24 relative overflow-hidden bg-gradient-to-b from-white to-gray-50">
        <div className="absolute inset-0 bg-texture opacity-30" />
        <div className="container mx-auto px-8 relative">
          <h2 className="text-4xl font-bold text-center mb-16">
            <span className="text-gradient">What Our Clients Say</span>
          </h2>
          <div className="max-w-4xl mx-auto">
            <div className="relative h-64">
              {reviews?.map((review, index) => (
                <div
                  key={review.id}
                  className={`absolute inset-0 transition-all duration-500 transform ${
                    index === activeTestimonial
                      ? 'opacity-100 translate-x-0'
                      : index < activeTestimonial
                      ? 'opacity-0 -translate-x-full'
                      : 'opacity-0 translate-x-full'
                  }`}
                >
                  <div className="card-hover p-8 rounded-2xl">
                    <div className="flex text-[#81ae40] mb-6">
                      {[...Array(review.rating)].map((_, i) => (
                        <Star key={i} className="w-6 h-6" fill="#81ae40" />
                      ))}
                    </div>
                    <p className="text-lg text-gray-700 mb-6">{review.review_text}</p>
                    <div>
                      <p className="font-semibold text-[#183043] reviewer-name">{review.reviewer_name}</p>
                      <p className="text-[#81ae40] reviewer-role">{review.reviewer_role}</p>
                    </div>
                  </div>
                </div>
              ))}
              <button
                onClick={prevTestimonial}
                className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-12 bg-white/90 p-2 rounded-full shadow-lg hover:bg-[#81ae40] hover:text-white transition-all"
                aria-label="Previous testimonial"
              >
                <ChevronLeft className="w-6 h-6" aria-hidden="true" />
              </button>
              <button
                onClick={nextTestimonial}
                className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-12 bg-white/90 p-2 rounded-full shadow-lg hover:bg-[#81ae40] hover:text-white transition-all"
                aria-label="Next testimonial"
              >
                <ChevronRight className="w-6 h-6" aria-hidden="true" />
              </button>
            </div>
            <div className="flex justify-center mt-8 space-x-2">
              {reviews?.map((_, index) => (
                <button
                  key={index}
                  className={`w-3 h-3 rounded-full transition-all duration-300 ${
                    index === activeTestimonial ? 'bg-[#81ae40] w-6' : 'bg-gray-300'
                  }`}
                  onClick={() => setActiveTestimonial(index)}
                />
              ))}
            </div>
          </div>
        </div>
      </section>

      <section
        ref={featuresRef}
        className="py-24 relative overflow-hidden bg-gradient-to-b from-white via-gray-50 to-white"
      >
        <div className="absolute inset-0 bg-texture opacity-30" />
        <div className="container mx-auto px-8 relative">
          <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-8">
            {[
              {
                icon: <Shield className="w-12 h-12 text-[#81ae40]" />,
                title: 'Comprehensive Coverage',
                description:
                  'Tailored insurance solutions that protect your assets and provide peace of mind.'
              },
              {
                icon: <Heart className="w-12 h-12 text-[#81ae40]" />,
                title: 'Personal Touch',
                description:
                  'We treat every client like family, providing personalized attention and care.'
              },
              {
                icon: <Sparkles className="w-12 h-12 text-[#81ae40]" />,
                title: 'Expert Guidance',
                description:
                  'Our experienced team helps you navigate complex insurance decisions with confidence.'
              },
              {
                icon: <Clock className="w-12 h-12 text-[#81ae40]" />,
                title: 'Quick Response',
                description:
                  'Fast, efficient service when you need it most, including 24/7 claims support.'
              }
            ].map((feature, index) => (
              <div key={index} className="group p-8 rounded-2xl card-hover">
                <div className="mb-6 transform transition-transform duration-300 group-hover:scale-110">
                  {feature.icon}
                </div>
                <h3 className="text-2xl font-semibold text-[#183043] mb-4 feature-title">
                  {feature.title}
                </h3>
                <p className="text-gray-600 hidden md:block">
                  {feature.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="py-24 relative overflow-hidden bg-gradient-to-br from-[#183043] to-[#0f1f2d]">
        <div className="container mx-auto px-8">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div>
              <h2 className="text-4xl font-bold text-white mb-8">Visit Our Office</h2>
              <div className="space-y-4 text-white/90">
                <a
                  href="https://www.google.com/maps/place/Janus+Insurance+Agency,+Inc/@42.6873479,-84.3037613,1219m/data=!3m2!1e3!4b1!4m6!3m5!1s0x8822df9b05d77bff:0x6618949601024575!8m2!3d42.6873441!4d-84.2988904!16s%2Fg%2F1hc268166?entry=ttu"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center hover:text-white transition-colors"
                >
                  <MapPin className="w-5 h-5 mr-3 text-[#81ae40]" />
                  1041 W. Grand River Ave, Williamston, MI
                </a>
                <a
                  href="tel:5176551665"
                  className="flex items-center hover:text-white transition-colors"
                >
                  <Phone className="w-5 h-5 mr-3 text-[#81ae40]" />
                  (517) 655-1665
                </a>
                <a
                  href="mailto:info@janusagents.com"
                  className="flex items-center hover:text-white transition-colors"
                >
                  <Mail className="w-5 h-5 mr-3 text-[#81ae40]" />
                  info@janusagents.com
                </a>
              </div>
            </div>
            <div className="relative h-96 rounded-lg overflow-hidden shadow-2xl">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2929.498087402054!2d-84.30376132346277!3d42.68734405916454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8822df9b05d77bff%3A0x6618949601024575!2sJanus%20Insurance%20Agency%2C%20Inc!5e0!3m2!1sen!2sus!4v1709901145439!5m2!1sen!2sus"
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Janus Insurance Agency Office Location"
                className="w-full h-full"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="min-h-screen bg-white">
        <Navbar />
        <Suspense fallback={<LoadingFallback />}>
          <Routes>
            <Route path="/team" element={<Team />} />
            <Route path="/auto-reform" element={<AutoReform />} />
            <Route path="/client-portal" element={<ClientPortal />} />
            <Route path="/products" element={<Products />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/refer-and-win" element={<ReferAndWin />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:slug" element={<BlogPost />} />
            <Route path="/" element={<HomePage />} />
          </Routes>
        </Suspense>
        <footer className="bg-[#183043] text-white py-12">
          <div className="container mx-auto px-8">
            <div className="grid md:grid-cols-4 gap-8">
              <div>
                <h3 className="text-xl mb-4 font-semibold">Contact Us</h3>
                <div className="space-y-2 text-white/80">
                  <p>1041 W. Grand River Ave</p>
                  <p>Williamston, MI 48895</p>
                  <a
                    href="tel:5176551665"
                    className="block hover:text-[#81ae40] transition-colors"
                  >
                    (517) 655-1665
                  </a>
                  <a
                    href="mailto:info@janusagents.com"
                    className="block hover:text-[#81ae40] transition-colors"
                  >
                    info@janusagents.com
                  </a>
                </div>
              </div>
              <div>
                <h3 className="text-xl mb-4 font-semibold">Quick Links</h3>
                <ul className="space-y-2">
                  <li>
                    <Link
                      to="/blog"
                      onClick={() => window.scrollTo(0, 0)}
                      className="text-white/80 hover:text-[#81ae40] transition-colors"
                    >
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/team"
                      onClick={() => window.scrollTo(0, 0)}
                      className="text-white/80 hover:text-[#81ae40] transition-colors"
                    >
                      Our Team
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/contact"
                      onClick={() => window.scrollTo(0, 0)}
                      className="text-white/80 hover:text-[#81ae40] transition-colors"
                    >
                      Contact Us
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/products"
                      onClick={() => window.scrollTo(0, 0)}
                      className="text-white/80 hover:text-[#81ae40] transition-colors"
                    >
                      Products
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/client-portal"
                      onClick={() => window.scrollTo(0, 0)}
                      className="text-white/80 hover:text-[#81ae40] transition-colors"
                    >
                      Client Portal
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/privacy-policy"
                      onClick={() => window.scrollTo(0, 0)}
                      className="text-white/80 hover:text-[#81ae40] transition-colors"
                    >
                      Privacy Policy
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <h3 className="text-xl mb-4 font-semibold">Products</h3>
                <ul className="space-y-2">
                  <li>
                    <Link
                      to="/products"
                      onClick={() => window.scrollTo(0, 0)}
                      className="text-white/80 hover:text-[#81ae40] transition-colors"
                    >
                      Auto Insurance
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/products"
                      onClick={() => window.scrollTo(0, 0)}
                      className="text-white/80 hover:text-[#81ae40] transition-colors"
                    >
                      Home Insurance
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/products"
                      onClick={() => window.scrollTo(0, 0)}
                      className="text-white/80 hover:text-[#81ae40] transition-colors"
                    >
                      Business Insurance
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/products"
                      onClick={() => window.scrollTo(0, 0)}
                      className="text-white/80 hover:text-[#81ae40] transition-colors"
                    >
                      Life Insurance
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <h3 className="text-xl mb-4 font-semibold">Hours</h3>
                <ul className="space-y-2 text-white/80">
                  <li>Monday - Friday</li>
                  <li>8:00 AM - 5:00 PM</li>
                  <li>Saturday - Sunday: Closed</li>
                </ul>
              </div>
            </div>
            <div className="border-t border-white/10 mt-8 pt-8 text-center text-white/60">
              <p>&copy; 2025 Janus Insurance Agency. All rights reserved.</p>
            </div>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;
