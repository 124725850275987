import { createClient } from '@supabase/supabase-js';
import { useState, useEffect } from 'react';

let supabaseInstance: ReturnType<typeof createClient> | null = null;
let imageCache: Record<string, string[]> = {};

export const supabase = (() => {
  if (!supabaseInstance) {
    const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
    const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

    if (!supabaseUrl || !supabaseAnonKey) {
      throw new Error('Missing Supabase environment variables');
    }

    supabaseInstance = createClient(supabaseUrl, supabaseAnonKey, {
      auth: {
        persistSession: true,
        autoRefreshToken: true,
      },
      db: {
        schema: 'public',
      },
    });
  }
  return supabaseInstance;
})();

export const handleSupabaseError = (error: any) => {
  console.error('Supabase error:', error);
  if (error.code === '42P01') {
    return 'Database table not found. Please ensure all migrations have been applied.';
  }
  return error.message || 'An unexpected error occurred';
};

export function useSupabaseQuery<T>(
  query: () => Promise<{ data: T | null; error: any }>,
  deps: any[] = []
) {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { data, error } = await query();
        
        if (error) {
          throw error;
        }

        setData(data);
        setError(null);
      } catch (err) {
        setError(handleSupabaseError(err));
        setData(null);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, deps);

  return { data, error, loading };
}

export async function prefetchHeroImages() {
  if (imageCache.heroImages) {
    return imageCache.heroImages;
  }

  try {
    const { data, error } = await supabase
      .from('hero_images')
      .select('*')
      .order('display_order');

    if (error) throw error;

    const imageUrls = data.map(img => img.image_url);
    imageCache.heroImages = imageUrls;

    // Preload images
    imageUrls.forEach(url => {
      const img = new Image();
      img.src = url;
    });

    return imageUrls;
  } catch (error) {
    console.error('Failed to prefetch hero images:', error);
    return [];
  }
}