import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

declare global {
  interface Window {
    gtag: (...args: any[]) => void;
    dataLayer: any[];
    performance: {
      getEntriesByType(type: string): PerformanceEntry[];
      mark(name: string): void;
      measure(name: string, startMark: string, endMark: string): void;
    } & Performance;
  }
}

export default function usePageTracking() {
  const location = useLocation();
  const { pathname, search, hash } = location;
  let maxScroll = 0;

  // Define trackScroll outside trackPage to make it accessible in cleanup
  const trackScroll = () => {
    const scrollPercentage = Math.round(
      (window.scrollY + window.innerHeight) / document.documentElement.scrollHeight * 100
    );
    if (scrollPercentage > maxScroll) {
      maxScroll = scrollPercentage;
      window.gtag('event', 'scroll_depth', {
        value: maxScroll,
        page_path: pathname + search + hash
      });
    }
  };

  useEffect(() => {
    const trackPage = () => {
      const startTime = performance.now();

      // Mark the start of page view
      performance.mark('page_view_start');

      // Get performance metrics
      const navigationEntry = performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming;
      const paintEntries = performance.getEntriesByType('paint');
      const firstPaint = paintEntries.find(entry => entry.name === 'first-paint')?.startTime;
      const firstContentfulPaint = paintEntries.find(entry => entry.name === 'first-contentful-paint')?.startTime;
      
      // Track page view with enhanced measurement
      window.gtag('event', 'page_view', {
        page_location: window.location.href,
        page_path: pathname + search + hash,
        page_title: document.title,
        send_to: 'G-89Z4MKJGPJ',
        engagement_time_msec: Math.round(performance.now() - startTime),
        viewport_size: `${window.innerWidth}x${window.innerHeight}`,
        user_agent: navigator.userAgent,
        // Add performance metrics
        page_load_time: navigationEntry?.loadEventEnd - navigationEntry?.startTime,
        dom_interactive_time: navigationEntry?.domInteractive - navigationEntry?.startTime,
        first_paint: firstPaint,
        first_contentful_paint: firstContentfulPaint,
        // Add scroll depth tracking
        max_scroll_percentage: 0,
        // Add connection info
        connection_type: (navigator as any).connection?.effectiveType || 'unknown',
        connection_speed: (navigator as any).connection?.downlink || 'unknown'
      });

      // Add scroll tracking
      window.addEventListener('scroll', trackScroll, { passive: true });
    };

    // Track initial page load
    trackPage();

    window.addEventListener('error', (event) => {
      window.gtag('event', 'exception', {
        description: event.message,
        fatal: false,
        page_path: pathname + search + hash,
        error_type: event.error?.name || 'Unknown',
        error_stack: event.error?.stack || 'Not available'
      });
    });

    let startTime = performance.now();
    const trackEngagement = () => {
      window.gtag('event', 'user_engagement', {
        engagement_time_msec: Math.round(performance.now() - startTime),
        page_path: pathname + search + hash,
        is_focused: document.hasFocus()
      });
      startTime = performance.now();
    };

    const engagementInterval = setInterval(trackEngagement, 15000);

    // Track outbound links
    const trackOutboundLink = (event: MouseEvent) => {
      const link = event.target as HTMLAnchorElement;
      if (link.tagName === 'A' && link.href && link.hostname !== window.location.hostname) {
        window.gtag('event', 'outbound_link', {
          url: link.href,
          page_path: pathname + search + hash
        });
      }
    };
    document.addEventListener('click', trackOutboundLink);

    return () => {
      clearInterval(engagementInterval);
      document.removeEventListener('click', trackOutboundLink);
      window.removeEventListener('scroll', trackScroll);
    };
  }, [pathname, search, hash]);
}